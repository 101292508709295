<template lang="pug">
Container
	h1 {{ header }}

Container
	UnitAbout

Container
	UnitMigration

Container
	UnitConsultation(type='employees')

Container
	UnitVictories

Container
	SliderDocuments

Container
	UnitHistory

Container
	UnitManager2

Container
	UnitPractice

Container
	UnitReputation

UiSubscribe
</template>

<script lang="ts" setup>
defineComponent({
	name: 'AboutPage',
});

const { header } = useMeta();
</script>
